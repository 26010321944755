import { userIsAuthorized, fetchUser, validateMagicLink } from "actions/userActions";
import { RoutePaths } from "app/routing/routing";
import { useAppState, useMergeAppState, useSetAppState } from "context/app";
import { useSetUserState } from "context/user";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useGetAuth = () => {
  const setAppState = useSetAppState();
  const setUserState = useSetUserState();
  const mergeAppState = useMergeAppState();
  const appState = useAppState();
  const history = useHistory();
  const location = useLocation();

  const handleMagicLink = async (authToken: string): Promise<void> => {
    try {
      const tokens = await validateMagicLink(authToken);
      mergeAppState({
        Authenticated: true,
        Authenticating: false,
        RefreshAuthenticating: false,
        LoginResult: tokens,
        isMagicLinkAccess: true,
        twoFactorRequired: false
      });
    } catch (error) {
      history.replace(RoutePaths.Login);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const authToken = searchParams.get("authToken");

    if (authToken && !appState.Authenticating) {
      mergeAppState({ Authenticating: true });
      handleMagicLink(authToken)
        .then(() => {
          const targetPath = location.pathname;
          history.replace(targetPath);
        })
        .catch((error) => {
          console.error("useGetAuth: Magic link handling failed", error);
          setAppState({ 
            Authenticating: false,
            Authenticated: false,
            LoginResult: undefined,
            isMagicLinkAccess: false,
            twoFactorRequired: false
          });
          history.replace(RoutePaths.Login);
        });
      return;
    }
  }, [location.search]);

  useEffect(() => {

    if (!appState.isMagicLinkAccess && !appState.RefreshAuthenticating) {
      const checkUserAuth = async () => {
        mergeAppState({ RefreshAuthenticating: true });
        const userAuth = userIsAuthorized();
        if (appState.Authenticated !== userAuth) {
          if (userAuth) {
            try {
              const result = await fetchUser();
              setUserState(result);
              mergeAppState({ Authenticated: true, RefreshAuthenticating: false });
            } catch(e) {
              mergeAppState({ Authenticated: false, RefreshAuthenticating: false });
            }
          } else {
            mergeAppState({ Authenticated: false, RefreshAuthenticating: false });
          }
        } else {
          mergeAppState({ RefreshAuthenticating: false });
        }
      };

      checkUserAuth();
      const intervalId = setInterval(checkUserAuth, 10000);
      return () => clearInterval(intervalId);
    }
  }, [appState.isMagicLinkAccess, appState.RefreshAuthenticating]);

  return null;
};
  
const AuthHandler: React.FC = () => {
  useGetAuth();
  return null;
};

export default AuthHandler; 