import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import styles from './dropDownSelect.module.scss';
import { parseHTML } from 'utils/commonFunctions';
import DropDownArrowLine from 'components/icons/dropDownArrowLine';
import _ from 'lodash';
import { useTooltip } from 'hooks/useTooltip';

type DropDownProps = {
  id: string;
  title?: string;
  placeholder?: string;
  startingValue?: string;
  options: Array<{ value: string; label: string }>;
  onChange?: (value: string) => void; 
  selectClass?: string;
  labelClass?: string;
  dropDownClass?: "questionnaire" | "application" | "userSettings" | "questionnaireTable" | "documents"
  register?: UseFormRegister<any>;
  locked?: boolean;
  errors?: FieldErrors<any>;
  isLoading?: boolean;
  isRequiredMessage?: string;
  isTablePreviewQuestion?: boolean;
  maxHeight?: number; // px
  accepted?: boolean;
  showErrorStyle?: boolean;
} & ({
  searchable?: undefined | false;
  refetch?: undefined
} | {
  searchable: true;
  refetch: (searchWord: string) => void;
})

const getLabel = (options: Array<{ value: string; label: string }>, value: string) => {
  return options.find((option) => option.value === value)?.label;
};

const DropDownSelect = ({
  id,
  placeholder,
  startingValue,
  options,
  onChange,
  title,
  selectClass,
  labelClass,
  register,
  locked = false,
  errors,
  dropDownClass = "questionnaire",
  isLoading,
  isRequiredMessage,
  isTablePreviewQuestion,
  maxHeight = 300,
  accepted,
  showErrorStyle,
  searchable,
  refetch
}: DropDownProps) => {
  const initialLabel = startingValue ? getLabel(options, startingValue) || '' : '';

  const [selectedValue, setSelectedValue] = useState<string>(startingValue || '');
  const [displayLabel, setDisplayLabel] = useState<string>(initialLabel);
  const [inputValue, setInputValue] = useState<string>(initialLabel);

  const [inputChanged, setInputChanged] = useState(false);
  const [dropDownChanged, setDropDownChanged] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const greyValues = [placeholder, options.find((option) => option.value === null || option.value == '')?.label];

  const {
    tooltipVisible,
    tooltipContent,
    forceHideTooltip,
    tooltipEventHandlers
  } = useTooltip();

  const optionHeight = 22;
  const hasErrors = showErrorStyle || ((errors && errors[id] || accepted === false) && !isTablePreviewQuestion);
  const modalRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const debouncedSearch = useCallback(
    _.debounce(searchable ? refetch! : () => { ''; }, 200, { leading: false, trailing: true }),
    [searchable, refetch]
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      (modalRef.current?.contains(event.target as Node) ||
        dropDownRef.current?.contains(event.target as Node)) === false
    ) {
      setDisplayLabel(selectedValue ? getLabel(options, selectedValue) || placeholder || '' : placeholder || '');
      setInputValue(selectedValue ? getLabel(options, selectedValue) || '' : '');
      setShowDropDown(false);
      forceHideTooltip();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputChanged(true);
    setShowDropDown(true);
    const newValue = event.target.value;
    setInputValue(newValue);
    const matchedOptions = options.filter(option =>
      option.label.toLowerCase() === newValue.toLowerCase()
    );
    if (matchedOptions.length == 1) {
      handleChange(matchedOptions[0]);
    }
    if (searchable)
      debouncedSearch(newValue);

    forceHideTooltip();
  };

  const doesInputMatchAnyOption = (inputValue: string) => {
    return options.some(option => option.label.toLowerCase() === inputValue.toLowerCase());
  };

  const getFilteredOptions = (options: Array<{ value: string; label: string }>, inputValue: string) => {
    const inputValueLower = inputValue.toLowerCase();
    const filteredOptions = options.filter(option =>
      option.label.toLowerCase().includes(inputValueLower)
    );
    if (((filteredOptions.length === 0 || doesInputMatchAnyOption(inputValue) ) && inputValue !== '') || !inputChanged) {
      return options;
    }
    return filteredOptions;
  };
  
  const filteredOptions = getFilteredOptions(options, inputValue);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (showDropDown) {
        handleClickOutside(event);
      }
    };
    document.body.addEventListener('click', handleDocumentClick);
    return () => {
      document.body.removeEventListener('click', handleDocumentClick);
    };
  }, [showDropDown]);

  
  const handleChange = ({ value, label }: { value: string; label: string }) => {
    setDropDownChanged(true);
    setSelectedValue(value);
    setInputValue(label);
    setDisplayLabel(label); 
    setShowDropDown(false);
    forceHideTooltip();
    if (onChange) {
      onChange(value);
    }
  };
  useEffect(() => {
    if (register && id) {
      register(id, { required: isRequiredMessage });
    }
  }, [id, isRequiredMessage, register]);

  useEffect(() => {
    if (options.length !== 0 && !dropDownChanged) {
      const newLabel = startingValue ? getLabel(options, startingValue) : '';
      setDisplayLabel(newLabel || '');
    }
    else if (options.length === 0 && startingValue){
      setDisplayLabel(startingValue);
    }
  }, [startingValue, options, placeholder, dropDownChanged]);

  useEffect(() => {
    if (startingValue === "null") {
      setSelectedValue("null");
      const newLabel = startingValue ? getLabel(options, startingValue) : placeholder;
      setDisplayLabel(newLabel || '');
      setInputValue(newLabel || '');
    }
  }, [startingValue, placeholder]);

  useEffect(() => {
    if (isTablePreviewQuestion && startingValue) {
      const newLabel = getLabel(options, startingValue);
      setDisplayLabel(newLabel || '');
      setInputValue(newLabel || '');
    }
  }, [startingValue, options, isTablePreviewQuestion]);
  
  const loadingSkeleton = () => {
    return (
      <div className={ classNames(styles.dropDownSelectParentDiv, styles[dropDownClass])}>
        <div className={classNames(styles.dropDownContainer, styles.locked, hasErrors && styles.errorBorder)}>
          <div className={styles.dropDownInfo}>
            {title && !isTablePreviewQuestion && <div className={classNames(styles.label, labelClass, hasErrors && styles.errorText)}>{parseHTML(title)}</div>}
            <div className={styles.valueSection}>
              <div className={classNames(styles.defaultValue, styles.greyValue)}>Loading...</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  if (isLoading) return loadingSkeleton();
  return (
    <div ref={dropDownRef} className={ classNames(styles.dropDownSelectParentDiv, styles[dropDownClass], isTablePreviewQuestion && styles.tableQuestionMobileStyle)}>
      <div className={classNames(styles.tooltip, tooltipVisible ? styles.showTooltip : '')}>{tooltipContent}</div>
      <div id={`${id}-dropdown`} className={classNames(styles.dropDownContainer, isTablePreviewQuestion && styles.locked, hasErrors && styles.errorBorder)} onClick={() => setShowDropDown(showDropDown => locked ? false: !showDropDown)}>
        <div className={styles.dropDownInfo}>
          {title && !isTablePreviewQuestion && <div className={classNames(styles.label, labelClass, hasErrors && styles.errorText)}>{parseHTML(title)}</div>}
          <div className={styles.valueSection}>
            <input
              type="text"
              value={inputValue ? inputValue : displayLabel}
              disabled={locked}
              onChange={handleInputChange}
              className={classNames(styles.defaultValue, styles.inputField, greyValues.includes(displayLabel) ? styles.greyValue : styles.selectedValue, inputValue == placeholder ? styles.lockedInputClass : styles.tableValueBlack)}
              placeholder={placeholder}
            />
            <div className={styles.dropDownArrowMobile}>
              <DropDownArrowLine />
            </div>
          </div>
        </div>
        { !isTablePreviewQuestion && <div className={styles.dropDownArrow}>
          <DropDownArrowLine />
        </div>}
      </div>
      {showDropDown && filteredOptions.length > 0 && (
        <div ref={modalRef} className={styles.modalContainer}>
          <div className={classNames(styles.dropDownField, styles.backgroundDiv)} style={{ height: `${filteredOptions.length * optionHeight}px`, maxHeight: `${maxHeight}px` }}/>
          <div className={classNames(styles.dropDownField, selectClass, isTablePreviewQuestion && styles.tableStyle)} style={{ height: `${filteredOptions.length * optionHeight}px`, maxHeight: `${maxHeight}px` }}>
            {filteredOptions.map((option, index) => (
              <div
                id={`${id}-option-${option.label}`}
                key={index}
                className={classNames(styles.option, {
                  [styles.selected]: option.value === selectedValue,
                  [styles.disabled]: locked
                })}
                onClick={() => !locked && handleChange(option)}
                {...tooltipEventHandlers(option.label)}
              >
                <div className={styles.optionLabel}>
                  {option.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      
      {errors && id && isTablePreviewQuestion !== true && (
        <div id={`${id}-error`}>
          <span className={styles.alert}>{errors[id]?.message}</span>
        </div>
      )}
    </div>
  );
};

export default DropDownSelect;
