import classNames from "classnames";
import CalendarIcon from "components/icons/calendar";
import AnimatedDatePicker from "components/UI/animatedDatePicker/datePicker";
import React, { FC, useEffect, useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import {
  DateFormat,
  formatDateToString,
  parseHTML,
} from "utils/commonFunctions";
import style from "./datePicker.module.scss";
import { dateFormatRegex, dateLengthRegex } from "utils/regexValidations";
import useScreenWidth from "hooks/useScreenWidth";

interface DatePickerProps {
  id: string;
  label?: string;
  value?: Date;
  className?: string;
  labelClassName?: string;
  register?: UseFormRegister<any>;
  errors?: FieldErrors<any>;
  locked?: boolean;
  onChange?: (date: string) => void;
  maxDate?: Date;
  minDate?: Date;
  isTablePreviewQuestion?: boolean;
  accepted?: boolean;
  showErrorStyle?: boolean;
}

const DatePicker: FC<DatePickerProps> = ({
  id,
  label,
  value,
  className,
  labelClassName,
  errors,
  locked,
  onChange,
  maxDate,
  minDate,
  isTablePreviewQuestion,
  accepted,
  showErrorStyle,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Date | undefined>(value);
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [hasCalendarChanged, setHasCalendarChanged] = useState(false);
  const width = useScreenWidth();

  const hasErrors =
    showErrorStyle ||
    (((errors && errors[id]) || accepted === false) && !isTablePreviewQuestion);

  const formatDate = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  const onCalendarChange = (selectedDate?: Date) => {
    if (!selectedDate) setShowCalendar(false);
    if (!(selectedDate instanceof Date)) return;

    const dateString = formatDateToString(
      selectedDate,
      DateFormat.YYYY_MM_DD_dashes
    );
    setSelectedValue(selectedDate);
    setInputValue(formatDate(selectedDate));
    setHasCalendarChanged(true);
    setShowCalendar(false);
    onChange && onChange(dateString);
  };

  const handleTextFieldClick = (e: any) => {
    if (!locked) {
      setShowCalendar(true);
    }
  };

  useEffect(() => {
    if (!hasCalendarChanged) {
      setSelectedValue(value);
    }
  }, [value]);
  
  function formatDateInput(value?: string): string {
    // Remove all non-numeric characters
    if (!value) return "";
    const digits = value.replace(/\D/g, '');
  
    // Extract year, month, and day
    const year = digits.slice(0, 4);
    let month = digits.slice(4, 6);
    let day = digits.slice(6, 8);
  
    // Adjust month to be between 1 and 12
    const numericMonth = parseInt(month, 10);
    if (!isNaN(numericMonth)) {
      if (numericMonth > 12) {
        month = parseInt(month[0], 10).toString().padStart(2, '0');
        day = digits.slice(5, 8)
      }
    }
  
    // Adjust day to be valid for the given month and year
    let numericDay = parseInt(day, 10);
    if (!isNaN(numericDay)) {
      const maxDays = new Date(parseInt(year, 10) || 0, numericMonth, 0).getDate(); // Get max days for the month
      if (numericDay > maxDays) {
        numericDay = maxDays; 
        day = numericDay.toString()
      }
      if (day[0] > '1') {
        day = day.padStart(2, '0');
      }
    }
  
    // Concatenate parts into YYYY-MM-DD format
    const formatted = [year, month, day].filter(Boolean).join('-');
  
    return formatted;
  }
  
  const handleChange = (event: any) => {
    let newValue = event.target.value;
    newValue = formatDateInput(newValue);
    
    // Remove leading zeros from month and day before parsing
    const [year, monthWithZero, dayWithZero] = newValue.split('-');
    const month = monthWithZero ? String(parseInt(monthWithZero, 10)) : '';
    const day = dayWithZero ? String(parseInt(dayWithZero, 10)) : '';
    
    const parsed = new Date(Number(year), Number(month) - 1, Number(day));

    if (!dateLengthRegex.test(newValue)){
      setSelectedValue(undefined);
    }

    if ((newValue.length === 4 || newValue.length === 7) && (inputValue?.length ?? 0) < newValue.length) newValue = newValue.concat('-');
    
    //TODO - Figure out why this extra dateformat makes the formatting work
    dateFormatRegex.test(newValue)
    if (dateFormatRegex.test(newValue) && !isNaN(parsed as any)) {
      setSelectedValue(parsed);
    } else {
      setSelectedValue(undefined);
    }
    setInputValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <div
      className={classNames(
        style.wrapperDiv,
        isTablePreviewQuestion && style.tableQuestionMobileStyle
      )}
    >
      {showCalendar && !locked && (
        <AnimatedDatePicker
          close={onCalendarChange}
          value={selectedValue}
          maxDate={maxDate}
          minDate={minDate}
        />
      )}

      <div className={classNames(style.inputDiv)}>
        <div
          className={classNames(
            style.dateContainer,
            locked && style.locked,
            hasErrors && style.errorBorder
          )}
        >
          {label && !isTablePreviewQuestion && (
            <label
              htmlFor={label}
              className={classNames(
                style.label,
                labelClassName,
                hasErrors && style.errorText
              )}
            >
              {parseHTML(label)}
            </label>
          )}

          <input
            type="text"
            className={classNames(style.dateText)}
            placeholder={label}
            onChange={handleChange}
            disabled={locked}
            onKeyDown={() => setSelectedValue(undefined)}
            value={selectedValue ? formatDate(selectedValue) : inputValue}
            
          />
        </div>
        {(!isTablePreviewQuestion || (isTablePreviewQuestion && width >= 720)) &&
        <div
          className={classNames(
            style.calendarIcon,
            hasErrors && style.errorBorder
          )}
          onClick={handleTextFieldClick}
        >
          <CalendarIcon />
        </div>
        }
      </div>

      {errors &&
      id &&
      errors[id]?.message &&
      isTablePreviewQuestion !== true && (
        <div>
          <span id={`${id}-error`} className={style.error}>{errors[id]?.message}</span>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
