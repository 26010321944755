import { QuestionDataType } from "components/questionnaire/questionnaireComponents/question/question";
import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";
import useSWR, { mutate } from "swr";
import { fileUploadSize } from "./actionConts";
import { IntStatus } from "utils/commonFunctions";
import { useStickySWR } from "hooks/useStickySWR";
import useSWRInfinite from "swr/infinite";
export interface QuestionnaireFilters {
  fileId: string;
  filter?: string[];
  searchWord?: string;
  orderBy?: string;
  sortOrder?: string;
  intStatus?: string;
}

export interface UserQuestionnaireFilters {
  searchWord?: string;
  responsibleLawyerID?: string;
  orderBy?: string;
  sortOrder?: string;
  intStatus?: IntStatus[];
  pageNumber?: number;
  rowNumber?: number;
}

export interface Questionnaire {
  contid: string;
  fileid: string;
  questionnaireId: number;
  questionnaireName: string;
  completionPerc: number;
  status: string;
  intStatus: number;
}

export interface ReviewQuestionnaire {
  matterNo: string; 
  clientName: string; 
  matterDescription: string;
  fileID: string; 
  contID: string; 
  questionaireID: number;
  questionnaire: string;
  intStatus: IntStatus;
}

export interface QuestionnaireInfoFilters {
  contId: number;
  fileId: number;
  questionnaireId: number;
}

export interface TableQuestionRecord{
  recordID: number,
  internalRecordID?: number | null,
  questions: QuestionTableRecord[]
}

export interface QuestionTableRecord{
  questionID: number,
  value: string,
  locked: boolean,
}

export interface QuestionnairesTableRow {
  contId: string;
  fileId: string;
  name: string;
  questionnaireId: number;
  progress: number | 0;
  status: string;
  intStatus: number;
}

export interface Section {
  sectionID: number;
  txtLabel: string;
  questions: QuestionDataType[];
  bit_isTable?: boolean;
  records?: TableQuestionRecord[];
  accepted: boolean | null;
}

export interface QuestionnaireInfo {
  id: string;
  sections: Section[];
  error?: any;
}

export interface SubmitQuestionnaire {
  contId: number;
  fileId: number;
  questionnaireId: number;
  intStatus: number;
  questionnaireInfo: QuestionnaireInfo;
  files: any[];
}

const getQuestionnaires = async (params: QuestionnaireFilters): Promise<Questionnaire[]> => {
  const result = await base.get(apiEndpoints.questionnaire, { params });
  return result.data;
};

const useGetQuestionnaires = (params: QuestionnaireFilters) => {
  const { 
    data, error, mutate, noDataFound 
  } = useStickySWR<QuestionnaireFilters, Questionnaire>([apiEndpoints.questionnaire, params], () => getQuestionnaires(params));

  return { data, error, mutate, noDataFound };
};

const getQuestionnaireInfo = async (paramsString : QuestionnaireInfoFilters): Promise<QuestionnaireInfo> => {
  const result = await base.get(apiEndpoints.questionnaireInfo, {params: paramsString});
  return result.data;
};

const useGetQuestionnaireInfo = ({ contId, fileId, questionnaireId }: QuestionnaireInfoFilters) => {
  const { data, error } = useSWR([apiEndpoints.questionnaireInfo, contId, fileId, questionnaireId], () => getQuestionnaireInfo({ contId, fileId, questionnaireId }));
  return { data, error };
};

const getUserQuestionnaires = async (params: UserQuestionnaireFilters): Promise<any[]> => {
  const result = await base.get(apiEndpoints.userQuestionnaire, { params });
  return result.data;
};

const useGetUserQuestionnaires = (params: UserQuestionnaireFilters) => {
  const getKey = (pageIndex: number, previousPageData: any[] | null) => {
    if (previousPageData && previousPageData.length === 0) return null;
    return [apiEndpoints.applications, { ...params, pageNumber: pageIndex + 1 }];
  };

  const fetcher = async ([url, params]: [string, UserQuestionnaireFilters]) => {
    const response = await getUserQuestionnaires(params);
    return response;
  };

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
    keepPreviousData: false,
  });
  
  const applicationsData = data ? data.flat() : [];
  const hasMore = data && data[data.length - 1]?.length === params.rowNumber;

  return {
    data: applicationsData,
    error,
    mutate,
    isValidating,
    size,
    setSize,
    hasMore,
    noDataFound: applicationsData && applicationsData.length === 0,
  };
};


const saveQuestionnaireInfo = async (questionnaireData: SubmitQuestionnaire): Promise<any> => {
  const result = await base.put(apiEndpoints.saveQuestionnaire, questionnaireData,
    {
      maxBodyLength: fileUploadSize,
      maxContentLength: fileUploadSize
    });
  
  mutate([apiEndpoints.questionnaireInfo, questionnaireData.contId, questionnaireData.fileId, questionnaireData.questionnaireId ]);
  return result.data;
};

export {
  useGetQuestionnaires,
  useGetQuestionnaireInfo,
  useGetUserQuestionnaires,
  saveQuestionnaireInfo
};